import Immutable from 'seamless-immutable';

const { createReducer, createActions } = require('reduxsauce');

const { Types, Creators } = createActions({
  resetGuest: [],
  setGuestInfo: ['info'],
  validateGuestEmail: ['email', 'cb'],
  validateGuestEmailSuccess: [],
  validateGuestEmailError: ['errorMessage'],
  createGuestCreditCard: ['token', 'gRecaptchaResponseData'],
  createGuestCreditCardDone: ['creditCard'],
  createGuestCreditCardError: ['errorMessage'],
  makeGuestPayment: ['token', 'email', 'firstName', 'lastName'],
  makeGuestPaymentDone: ['receipt'],
  makeGuestPaymentError: ['errorMessage'],
});

export const GuestTypes = Types;
export default Creators;

const INITIAL_STATE = Immutable.from({
  guestInfo: {
    email: '',
    firstName: '',
    lastName: '',
  },
  creditCard: null,
  receipt: null,
  isValidatingGuestEmail: false,
  validatingGuestEmailError: '',
  isCreatingCreditCard: false,
  isCreatingCreditCardDone: false,
  creatingCreditCardError: '',
  isMakingGuestPayment: false,
  isMakingGuestPaymentDone: false,
  makingGuestPaymentError: '',
});

const resetGuest = () => INITIAL_STATE;

const setGuestInfo = (state, { info }) => state.merge({
  guestInfo: state.guestInfo.merge(info),
});

const validateGuestEmailSuccess = (state) => state.merge({
  validatingGuestEmailError: '',
});

const validateGuestEmailError = (state, { errorMessage }) => state.merge({
  validatingGuestEmailError: errorMessage,
});

const createGuestCreditCard = (state) => state.merge({
  isCreatingCreditCard: true,
  isCreatingCreditCardDone: false,
  creatingCreditCardError: '',
});

const createGuestCreditCardDone = (state, { creditCard }) => state.merge({
  creditCard,
  isCreatingCreditCard: false,
  isCreatingCreditCardDone: true,
  creatingCreditCardError: '',
});

const createGuestCreditCardError = (state, { errorMessage }) => state.merge({
  isCreatingCreditCard: false,
  isCreatingCreditCardDone: true,
  creatingCreditCardError: errorMessage,
});

const makeGuestPayment = (state) => state.merge({
  isMakingGuestPayment: true,
  isMakingGuestPaymentDone: false,
  makingGuestPaymentError: '',
});

const makeGuestPaymentDone = (state, { receipt }) => state.merge({
  receipt,
  isMakingGuestPayment: false,
  isMakingGuestPaymentDone: true,
  makingGuestPaymentError: '',
});

const makeGuestPaymentError = (state, { errorMessage }) => state.merge({
  isMakingGuestPayment: false,
  isMakingGuestPaymentDone: true,
  makingGuestPaymentError: errorMessage,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.RESET_GUEST]: resetGuest,
  [Types.SET_GUEST_INFO]: setGuestInfo,
  [Types.VALIDATE_GUEST_EMAIL_SUCCESS]: validateGuestEmailSuccess,
  [Types.VALIDATE_GUEST_EMAIL_ERROR]: validateGuestEmailError,
  [Types.CREATE_GUEST_CREDIT_CARD]: createGuestCreditCard,
  [Types.CREATE_GUEST_CREDIT_CARD_DONE]: createGuestCreditCardDone,
  [Types.CREATE_GUEST_CREDIT_CARD_ERROR]: createGuestCreditCardError,
  [Types.MAKE_GUEST_PAYMENT]: makeGuestPayment,
  [Types.MAKE_GUEST_PAYMENT_DONE]: makeGuestPaymentDone,
  [Types.MAKE_GUEST_PAYMENT_ERROR]: makeGuestPaymentError,
});
