import {
  call, put, takeLatest, select,
} from 'redux-saga/effects';
import { push } from 'react-router-redux';

import ReservationsApi from 'apis/supremeGolfApi/ReservationsApi';

import ReservationsActions, { ReservationsTypes } from 'reducers/reservations';
import GuestActions from 'reducers/guest';

import { BOOKINGS } from 'utils/routes';
import { statusFromTabId } from 'utils/bookingStatus';
import { getQsAndParams } from 'utils/routeHelpers';

export function* requestReservations({ status }) {
  try {
    const { sortBy } = yield select(({ reservations }) => reservations);
    let order;
    let sortByBackend;
    switch (sortBy) {
      case 'a-z':
        sortByBackend = 'alphabetically';
        order = 'asc';
        break;
      case 'z-a':
        sortByBackend = 'alphabetically';
        order = 'desc';
        break;
      default:
        sortByBackend = 'tee_off_at';
        order = 'desc';
        break;
    }
    const reservations = yield call(ReservationsApi.getReservations, {
      status,
      sortBy: sortByBackend,
      order,
    });
    yield put(ReservationsActions.getReservationsDone(status, reservations));
  } catch (error) {
    yield put(ReservationsActions.getReservationsError(error.message));
  }
}

export function* requestReservation({ id, accessToken }) {
  try {
    const reservation = yield call(ReservationsApi.getReservation, { id, accessToken });
    const { email, firstName, lastName } = reservation.reservation.inviter;
    if (accessToken) yield put(GuestActions.setGuestInfo({ email, firstName, lastName }));
    yield put(ReservationsActions.getReservationDone(reservation));
  } catch (error) {
    const errorMessage = error.message ?? 'Reservation not found';
    yield put(ReservationsActions.getReservationError(errorMessage));
  }
}

export function* requestCancelReservation({ id, cb }) {
  try {
    const { query } = yield select((state) => state.router.location);
    const reservation = yield call(ReservationsApi.postCancelReservation, {
      id, accessToken: query?.accessToken,
    });
    if (!query?.accessToken) yield put(push(`/my-account/bookings/past/${id}`));
    yield put(ReservationsActions.postCancelReservationDone(reservation));
    if (cb) cb();
  } catch (error) {
    const errorMessage = error?.response?.data?.error ?? 'Reservation not found';
    yield put(ReservationsActions.postCancelReservationError(errorMessage));
    if (cb) cb();
  }
}

export function* requestCancellationFeedback({ id, userMessage }) {
  try {
    yield call(ReservationsApi.postCancellationFeedback, { id, userMessage });

    yield put(ReservationsActions.postCancellationFeedbackDone());
  } catch (error) {
    yield put(ReservationsActions.postCancellationFeedbackError(error.message));
  }
}

export function* setSortByHandler() {
  const { location } = yield select(({ router }) => router);
  const { params: { status } } = getQsAndParams(location, BOOKINGS);
  yield* requestReservations({ status });
}

export function* setBookingsTab({ selectedTabId }) {
  const status = statusFromTabId(selectedTabId);

  yield put(push(BOOKINGS.replace(':status', status)));
}

export function* requestPrepareUpdateReservation({ id, qty, cb }) {
  try {
    const { updateReservation } = yield call(ReservationsApi.updatePlayersPrepare, { id, qty });

    yield put(ReservationsActions.prepareUpdateReservationDone(updateReservation));
    cb();
  } catch (error) {
    const errorMessage = error.response.data.error ?? 'There was some issue updating the player.';
    yield put(ReservationsActions.prepareUpdateReservationError(errorMessage));
  }
}

export function* requestUpdateReservation({
  id, qty, creditCardId, cb,
}) {
  try {
    yield call(ReservationsApi.updateReservation, { id, qty, creditCardId });

    yield put(ReservationsActions.updateReservationDone());
    cb();
  } catch (error) {
    const errorMessage = error.response.data.error ?? 'There was some issue modifying the booking.';
    yield put(ReservationsActions.updateReservationError(errorMessage));
  }
}

function* requestReservationsWatcher() {
  yield takeLatest(ReservationsTypes.GET_RESERVATIONS, requestReservations);
}

function* requestReservationsSilentWatcher() {
  yield takeLatest(ReservationsTypes.GET_RESERVATIONS_SILENT, requestReservations);
}

function* requestReservationWatcher() {
  yield takeLatest(ReservationsTypes.GET_RESERVATION, requestReservation);
}

function* requestReservationSilentWatcher() {
  yield takeLatest(ReservationsTypes.GET_RESERVATION_SILENT, requestReservation);
}

function* requestCancelReservationWatcher() {
  yield takeLatest(ReservationsTypes.POST_CANCEL_RESERVATION, requestCancelReservation);
}

function* requestCancellationFeedbackWatcher() {
  yield takeLatest(
    ReservationsTypes.POST_CANCELLATION_FEEDBACK,
    requestCancellationFeedback,
  );
}

function* setBookingsTabWatcher() {
  yield takeLatest(ReservationsTypes.SET_BOOKINGS_TAB, setBookingsTab);
}

function* setSortByWatcher() {
  yield takeLatest(ReservationsTypes.SET_SORT_BY_RESERVATIONS, setSortByHandler);
}

function* prepareUpdateReservationWatcher() {
  yield takeLatest(ReservationsTypes.PREPARE_UPDATE_RESERVATION, requestPrepareUpdateReservation);
}

function* updatedReservationWatcher() {
  yield takeLatest(ReservationsTypes.UPDATE_RESERVATION, requestUpdateReservation);
}

export default [
  requestReservationsWatcher,
  requestReservationsSilentWatcher,
  requestReservationWatcher,
  requestReservationSilentWatcher,
  requestCancelReservationWatcher,
  requestCancellationFeedbackWatcher,
  setBookingsTabWatcher,
  setSortByWatcher,
  prepareUpdateReservationWatcher,
  updatedReservationWatcher,
];
