import SupremeGolfApiClient from './SupremeGolfApiClient';

const USERS_ENDPOINT = 'v6/users';

const RESEND_UNLOCK_INSTRUCTIONS_ENDPOINT = `${USERS_ENDPOINT}/resend-unlock-instructions`;
const SOCIAL_SIGN_IN_ENDPOINT = `${USERS_ENDPOINT}/sign-in/socially`;
const UNLOCK_ENDPOINT = `${USERS_ENDPOINT}/unlock`;
const USERS_PASSWORD_CHANGE = `${USERS_ENDPOINT}/password-change`;
const REWARDS_POINTS_ENDPOINT = `${USERS_ENDPOINT}/rewards_points`;
const REWARDS_PROFILE_ENDPOINT = `${USERS_ENDPOINT}/rewards_profile_urls`;
const USERS_SIGN_OUT_ENDPOINT = `${USERS_ENDPOINT}/sign-out`;
const USERS_VALID_ENDPOINT = `${USERS_ENDPOINT}/valid`;
const USERS_SIGN_IN_WITH_OTT = `${USERS_ENDPOINT}/sign-in-with-one-time-token`;
const USERS_OTT = `${USERS_ENDPOINT}/one-time-token`;
const REQUEST_DELETION_ENDPOINT = `${USERS_ENDPOINT}/request_deletion`;
const TRACK_SESSIONS_ENDPOINT = `${USERS_ENDPOINT}/session`;

class UsersApi {
  static async userProfile() {
    const response = await SupremeGolfApiClient.get(USERS_ENDPOINT);
    const { data } = response;
    return data;
  }

  static async signUp(
    firstName,
    lastName,
    addressZipcode,
    addressCountryIso2,
    email,
    password,
    gRecaptchaResponseData,
    referralAttributes,
    iterableTracking,
    invitationToken,
    reservationAccessToken,
    rwgParams,
    utmParams = {},
  ) {
    const params = {
      firstName,
      lastName,
      addressZipcode,
      addressCountryIso2,
      email,
      password,
      gRecaptchaResponseData,
      referralAttributes,
      ...utmParams,
      iterableTracking,
    };
    if (invitationToken) params.invitationToken = invitationToken;
    if (rwgParams?.rwgToken) params.rwgToken = rwgParams.rwgToken;
    if (rwgParams?.rwgCourseId) params.rwgCourseId = rwgParams.rwgCourseId;
    if (reservationAccessToken) params.reservationAccessToken = reservationAccessToken;

    const response = await SupremeGolfApiClient.post(USERS_ENDPOINT, params);
    const { data } = response;

    return data;
  }

  static async validateEmail(email) {
    const response = await SupremeGolfApiClient.get(USERS_VALID_ENDPOINT, {
      email,
    });
    const { data } = response;

    return data;
  }

  static async editUser(params) {
    const response = await SupremeGolfApiClient.patch(USERS_ENDPOINT, params);
    const { data } = response;

    return data;
  }

  static async getRewardsPoints() {
    const response = await SupremeGolfApiClient.get(`${REWARDS_POINTS_ENDPOINT}`);
    const { data } = response;
    return data;
  }

  static async getRewardsProfileUrl() {
    const response = await SupremeGolfApiClient.get(`${REWARDS_PROFILE_ENDPOINT}`);
    const { data } = response;
    return data.user;
  }

  static async signInSocially(
    provider,
    accessToken,
    uid,
    email,
    iterableTracking,
    invitationToken,
    reservationAccessToken,
    rwgParams,
    utmParams = {},
    rawInfo = {},
  ) {
    const payload = {
      provider,
      token: accessToken,
      uid,
      email,
      rawInfo,
      ...utmParams,
      iterableTracking,
    };
    if (invitationToken) payload.invitationToken = invitationToken;
    if (rwgParams?.rwgToken) payload.rwgToken = rwgParams?.rwgToken;
    if (rwgParams?.rwgCourseId) payload.rwgCourseId = rwgParams?.rwgCourseId;
    if (reservationAccessToken) payload.reservationAccessToken = reservationAccessToken;

    const response = await SupremeGolfApiClient.post(SOCIAL_SIGN_IN_ENDPOINT, payload);
    const { data } = response;

    return data;
  }

  static async signOut() {
    const response = await SupremeGolfApiClient.post(USERS_SIGN_OUT_ENDPOINT);
    const { data } = response;

    return data;
  }

  static async unlock(unlockToken) {
    const response = await SupremeGolfApiClient.post(UNLOCK_ENDPOINT, {
      unlockToken,
    });
    const { data } = response;

    return data;
  }

  static async resendUnlockInstructions(email) {
    const response = await SupremeGolfApiClient.post(
      RESEND_UNLOCK_INSTRUCTIONS_ENDPOINT,
      {
        email,
      },
    );
    const { data } = response;

    return data;
  }

  static async passwordChange(currentPassword, password, passwordConfirmation) {
    const response = await SupremeGolfApiClient.post(USERS_PASSWORD_CHANGE, {
      currentPassword,
      password,
      passwordConfirmation,
    });
    const { data } = response;

    return data;
  }

  static async oneTimeToken() {
    const response = await SupremeGolfApiClient.post(USERS_OTT);

    const { data } = response;

    return data;
  }

  static async signInWithOneTimeToken(oneTimeToken) {
    const response = await SupremeGolfApiClient.post(USERS_SIGN_IN_WITH_OTT, {
      oneTimeToken,
    });

    const { data } = response;

    return data;
  }

  static async setUserBanStatus(id, isBanned) {
    const params = {
      id,
    };

    const action = isBanned ? 'ban' : 'unban';
    const API = `${USERS_ENDPOINT}/${id}/${action}`;

    const response = await SupremeGolfApiClient.post(
      API,
      params,
    );

    return response.data;
  }

  static async requestDeletion() {
    await SupremeGolfApiClient.post(REQUEST_DELETION_ENDPOINT);
  }

  static async deleteUser(token) {
    await SupremeGolfApiClient.delete(USERS_ENDPOINT, { token });
  }

  static async trackSession() {
    const response = await SupremeGolfApiClient.post(TRACK_SESSIONS_ENDPOINT);
    return response.data;
  }
}

export default UsersApi;
